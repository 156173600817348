import React, { useState } from 'react';
import { connect } from 'react-redux';

import { i18n } from '@lingui/core';
import { Trans, t } from '@lingui/macro';
import orderBy from 'lodash/orderBy';
import PropTypes from 'prop-types';
import { Icon, Loader } from 'semantic-ui-react';
import styled from 'styled-components';

import * as FileSaver from 'file-saver';

import HelpTooltip from 'components/ui/HelpTooltip';
import HoverableIcon from 'components/ui/icon/HoverableIcon';

import { logEvent } from 'utils/analytics';
import commonPropTypes from 'utils/commonPropTypes';
import { capitalize, removeHtmlTags } from 'utils/helpers';

import * as svars from 'assets/style/variables';

import { compileExportFilename, smallIconMargin } from './utils';

// Maximum number of characters allowed for a tabular file filename
// This is due to an MS Excel limit of filename size
export const TABLE_FILENAME_MAX_N_CHARS = 205;

const WarningMessage = styled.span`
  margin-left: ${svars.spaceNormal};
  color: ${svars.colorWarning};
  font-size: ${svars.fontSizeSmaller};
  font-weight: ${svars.fontWeightBase};
`;

// Add docstring

// Extract string from formatted value, when they are jsx elements
// @param {object} obj
// @returns {string}
function extractStringFromFormattedValue(obj) {
  if (typeof obj === 'string') return obj;
  if (React.isValidElement(obj)) {
    return removeHtmlTags(obj.props.value);
  }
  if (Array.isArray(obj)) {
    return obj.map((e) => extractStringFromFormattedValue(e)).join(' ');
  }
  return obj?.toString() || obj;
}

const selectColumnsData = (data, columns, sorted, nMaxExportedRows) => {
  let formatted = data;
  if (sorted) {
    formatted = orderBy(formatted, Object.keys(sorted), Object.values(sorted));
  }
  formatted = formatted.map((row) => {
    const selected = {};
    columns.forEach(({ key, id, label, ...others }) => {
      let value;
      // Key can be `key` or `id`
      if (id) {
        value = row[id];
      } else if (key) {
        value = row[key];
      }
      // Handle formatting and accessors
      if (typeof others.accessor === 'string') {
        value = row[others.accessor];
      } else if (others.accessor) {
        value = others.accessor(row);
      }
      if (others.formatter) {
        value = others.formatter(value);
      }
      value = extractStringFromFormattedValue(value);

      selected[label] = value;
    });
    return selected;
  });
  return nMaxExportedRows ? formatted.slice(0, nMaxExportedRows) : formatted;
};

const fileType =
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const fileExtension = 'xlsx';

export const exportAsXls = (
  baseData,
  columns,
  sorted,
  fileName,
  sheetName,
  callback,
  disclaimerText,
  nMaxExportedRows,
  defaultWidth = 20
) => {
  const csvData = selectColumnsData(
    baseData,
    columns,
    sorted,
    nMaxExportedRows
  );
  import('exceljs').then((exceljs) => {
    // Creating workbook and sheet
    const workbook = new exceljs.Workbook();
    const sheet = workbook.addWorksheet(sheetName);
    const disclaimerColumn = disclaimerText
      ? Math.min(columns.length, 1)
      : null;
    let headerRowNumber = 1;
    // If `disclaimerText`, use first row to set it and add an empty row
    if (disclaimerColumn) {
      sheet.getCell(1, disclaimerColumn).value = disclaimerText;
      sheet.getCell(1, disclaimerColumn).style = {
        font: { italic: true, size: 13 },
      };
      // Add empty row
      sheet.addRow({});
      // In this case, header is the 3rd row
      headerRowNumber = 3;
    }
    // Add header manually
    sheet.getRow(headerRowNumber).values = columns.map(({ label }) =>
      capitalize(i18n._(label))
    );
    sheet.getRow(headerRowNumber).height = 30;

    // Identify columns to permit `addRow`
    sheet.columns = columns.map(({ label }) => ({
      key: label,
    }));
    // Add columns alignment / format
    columns.forEach((column, i) => {
      const sheetColumn = sheet.getColumn(i + 1);
      sheetColumn.alignment = { vertical: 'middle', wrapText: true };
      const maxContentLength = Math.max(
        ...csvData.map((row) => {
          const value = row[column.label];
          return value ? String(value).length : 0;
        }),
        column.label.length
      );
      sheetColumn.width = Math.min(
        Math.max(
          // If content is more than 50 characters, set width to half of content length (in px)
          maxContentLength > 50 ? maxContentLength / 2 : maxContentLength,
          defaultWidth
        ),
        // Limit width to 180px
        120
      );
    });
    if (disclaimerColumn) {
      // Unwrap disclaimer text cell to avoid taking too much space
      sheet.getCell(1, disclaimerColumn).alignment = {
        wrapText: false,
      };
    }

    // Add data to sheet
    csvData.forEach((row) => sheet.addRow(row));

    // Add style and alignment for header row
    // NB : we do this after formatting columns, so it is not overridden
    sheet.getRow(headerRowNumber)._cells.forEach((cell) => {
      cell.style = { font: { bold: true, size: 13 } };
    });
    sheet.getRow(headerRowNumber).alignment = {
      vertical: 'middle',
      horizontal: 'center',
      wrapText: true,
    };

    // Write & download workbook
    workbook.xlsx.writeBuffer().then((data) => {
      const blob = new Blob([data], { useStyles: true, type: fileType });
      FileSaver.saveAs(blob, `${fileName}.${fileExtension}`);
      if (callback) callback();
    });
  });
};

const StyledGroup = styled(HoverableIcon.Group)`
  &&& {
    margin-right: ${svars.spaceNormal};
    &:hover {
      color: ${svars.accentColor};
    }
  }
`;

export function ExportAsXlsIcon({ onClick, disabled, hoverable, style }) {
  const Component = hoverable ? StyledGroup : Icon.Group;
  return (
    <Component
      size="large"
      style={{ marginRight: smallIconMargin, ...style }}
      disabled={disabled}
      onClick={onClick}
    >
      <Icon name="file excel outline" />
      <Icon
        corner
        name="arrow alternate circle down"
        style={{
          paddingLeft: smallIconMargin,
          paddingTop: smallIconMargin,
        }}
      />
    </Component>
  );
}
ExportAsXlsIcon.propTypes = {
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  hoverable: PropTypes.bool,
  style: commonPropTypes.style,
};
ExportAsXlsIcon.defaultProps = {
  disabled: false,
  hoverable: false,
  onClick: null,
  style: {},
};

export function BaseExportAsXlsButton({
  data,
  columns,
  sorted,
  viewFacet,
  exportName,
  disabled,
  style,
  helpTooltipPosition,
  disclaimerText,
  nMaxExportedRows,
}) {
  const [loading, setLoading] = useState(false);
  const onExport = () => {
    if (!loading && !disabled) {
      setLoading(true);
      const exportFileName = compileExportFilename(
        viewFacet && viewFacet.name,
        exportName
      );
      exportAsXls(
        data,
        columns,
        sorted,
        exportFileName,
        capitalize(t({ id: 'data' })),
        () => {
          setLoading(false);
          logEvent({
            category: 'Customer - Analyze',
            action: 'Export xls',
            label: exportFileName,
          });
        },
        disclaimerText,
        nMaxExportedRows
      );
    }
  };
  return (
    <span style={style} data-html2canvas-ignore="true">
      {loading ? (
        <Loader inline active size="mini" />
      ) : (
        <HelpTooltip
          compact
          mountNode={document.querySelector('#popup-root')}
          position={helpTooltipPosition}
          mouseEnterDelay={300}
          mouseLeaveDelay={50}
          help={capitalize(t({ id: 'export-as-table' }))}
          hoverable={false}
          trigger={
            <span>
              <ExportAsXlsIcon
                hoverable
                disabled={disabled || !data?.length}
                onClick={onExport}
              />
            </span>
          }
        />
      )}
      {nMaxExportedRows && data && nMaxExportedRows < data.length && (
        <WarningMessage>
          <Icon name="warning circle" />
          <Trans id="export-limited-to-150-chunks" />
        </WarningMessage>
      )}
    </span>
  );
}

BaseExportAsXlsButton.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  data: PropTypes.arrayOf(PropTypes.object),
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string.isRequired,
      label: commonPropTypes.i18nText.isRequired,
      formatter: PropTypes.func,
      accessor: PropTypes.func,
    })
  ).isRequired,
  sorted: PropTypes.objectOf(PropTypes.string),
  viewFacet: commonPropTypes.viewFacet,
  exportName: PropTypes.string,
  disabled: PropTypes.bool,
  style: commonPropTypes.style,
  helpTooltipPosition: PropTypes.string,
  // If set, text displayed in first cell (merged on all columns), before exported data
  disclaimerText: PropTypes.string,
  // If set, limit the number of exported rows
  nMaxExportedRows: PropTypes.number,
};

BaseExportAsXlsButton.defaultProps = {
  data: null,
  sorted: null,
  viewFacet: undefined,
  exportName: null,
  disabled: false,
  style: {},
  helpTooltipPosition: 'top center',
  disclaimerText: null,
  nMaxExportedRows: null,
};

export default React.memo(
  connect((state) => ({ viewFacet: state.view.viewFacet }))(
    BaseExportAsXlsButton
  )
);
