import { useCallback, useLayoutEffect, useMemo, useRef, useState } from 'react';

import { t } from '@lingui/macro';
import { useLingui, Trans } from '@lingui/react';
import throttle from 'lodash/throttle';
import PropTypes from 'prop-types';
import { Dropdown, Icon } from 'semantic-ui-react';
import styled from 'styled-components';

import { campaignMonitoringDataSelectorFactory } from 'store/monitor/monitorSlice';

import Header from 'components/ui/Header';
import {
  ExportAsImageIcon,
  exportElementAsImage,
} from 'components/ui/button/export-as/ExportAsImage';
import {
  ExportAsXlsIcon,
  exportAsXls,
} from 'components/ui/button/export-as/ExportAsXls';
import AlternativeChartDropdownPortal from 'components/ui/dashboard/AlternativeChartDropdownPortal';
import { AnalyticsAwareHoverableIconButton } from 'components/ui/icon/HoverableIcon';
import EmptyDataVisualization from 'components/ui/visualization/EmptyDataVisualization';
import LoadingDataVisualization from 'components/ui/visualization/LoadingDataVisualization';

import { dayFormatter } from 'utils/formatter';
import { capitalize } from 'utils/helpers';
import { useMemoizedFactorySelector } from 'utils/hooks';

import * as svars from 'assets/style/variables';

import commonPropTypes from 'utils/commonPropTypes';
import HelpTooltip from '../HelpTooltip';
import DataModal from './DataModal';
import useAlternativeDataSelectorPortal from './useAlternativeDataSelectorPortal';

const ChartSegment = styled.div`
  display: flex;
  flex-direction: column;
  background: white;
  margin: ${svars.spaceNormal};
  padding: ${svars.spaceNormalLarge};
  flex-grow: 1;
  border-radius: ${svars.borderRadius};
  border-color: ${svars.colorLightGrey};
  box-shadow: ${({ flat }) => (flat ? 'none' : svars.baseBoxShadow)};
`;

const WithDimensionsContainer = styled.div`
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

function ComponentWithDimensions({ Component, props, chartId }) {
  const targetRef = useRef();
  const [dimensions, setDimensions] = useState({ width: 0, height: 0 });
  const setDimensionsThrottled = useMemo(
    () => throttle(setDimensions, 800),
    [setDimensions]
  );

  useLayoutEffect(() => {
    if (targetRef.current) {
      setDimensionsThrottled({
        width: targetRef.current.clientWidth,
        height: targetRef.current.clientHeight,
      });
      return () => {
        setDimensionsThrottled.cancel();
      };
    }
    return null;
  }, [targetRef.current?.offsetWidth, targetRef.current?.offsetHeight]);

  return (
    <WithDimensionsContainer ref={targetRef}>
      {dimensions.height !== 0 ? (
        <Component
          height={dimensions.height}
          width={dimensions.width}
          {...props}
          chartId={chartId}
        />
      ) : null}
    </WithDimensionsContainer>
  );
}

ComponentWithDimensions.propTypes = {
  chartId: PropTypes.string,
  Component: PropTypes.func.isRequired,
  props: PropTypes.shape(),
};
ComponentWithDimensions.defaultProps = {
  props: { meta: {}, formatters: {} },
  chartId: null,
};

export function ChartMenu({
  disabled,
  toggleDataModal,
  exportAsImage,
  exportDataAsXls,
}) {
  return (
    <Dropdown
      disabled={disabled}
      direction="left"
      trigger={
        <AnalyticsAwareHoverableIconButton
          gaCategory="Scatter export modal"
          gaAction="Hide/show dot (from list)"
          name="ellipsis vertical"
          size={null}
          disabled={disabled}
        />
      }
      icon={null}
      style={{ margin: 'auto' }}
    >
      <Dropdown.Menu>
        {toggleDataModal ? (
          <Dropdown.Item
            onClick={toggleDataModal}
            content={t({ id: 'see-data' })}
            icon={
              <Icon.Group size="large">
                <Icon
                  style={{ marginRight: svars.spaceNormalLarge }}
                  name="file alternate outline"
                />
              </Icon.Group>
            }
          />
        ) : null}
        <Dropdown.Item
          onClick={exportAsImage}
          disabled={!exportAsImage}
          content={t({ id: 'export-as-image' })}
          icon={
            <ExportAsImageIcon
              style={{ marginRight: svars.spaceNormal }}
              hoverable={false}
            />
          }
        />
        <Dropdown.Item
          onClick={exportDataAsXls}
          content={t({ id: 'export-data' })}
          icon={
            <ExportAsXlsIcon
              style={{ marginRight: svars.spaceNormal }}
              hoverable={false}
            />
          }
        />
      </Dropdown.Menu>
    </Dropdown>
  );
}

ChartMenu.propTypes = {
  exportDataAsXls: PropTypes.func.isRequired,
  toggleDataModal: PropTypes.func,
  disabled: PropTypes.bool,
  exportAsImage: PropTypes.func,
};

ChartMenu.defaultProps = {
  disabled: false,
  exportAsImage: null,
  toggleDataModal: null,
};

const exportAsData =
  (data, meta, makeTableData, getTitle, makeExportTitle) => () => {
    const { data: tableData, columns } = makeTableData({ data, meta });
    columns.forEach((column) => {
      // Columns width interfere with export default styling
      delete column.width;
      if (column.id === 'date') {
        // Add formatter for date fields
        column.formatter = dayFormatter;
      }
    });
    exportAsXls(
      tableData,
      columns,
      null,
      makeExportTitle(getTitle(), t({ id: 'data' })),
      capitalize(t({ id: 'data' })),
      () => {},
      null,
      3000,
      20
    );
  };

const getDataModalTitle = (title, nodeId) =>
  `${title} ${
    document.getElementById(nodeId)?.firstChild?.firstChild?.firstChild
      ?.innerText || ''
  }`;

function DashboardElement({
  chartId,
  title,
  titleHelper,
  titleHelperHeader,
  noDataMessage,
  component,
  meta: baseMeta,
  formatters,
  accessor,
  loading,
  makeTableData,
  getExportTitle,
  hideMenu,
  testid,
}) {
  useLingui();
  const [dataModalIsOpen, setDataModalIsOpen] = useState(false);
  const toggleDataModal = useCallback(
    () => setDataModalIsOpen(!dataModalIsOpen),
    [dataModalIsOpen]
  );
  const baseData = useMemoizedFactorySelector(
    campaignMonitoringDataSelectorFactory,
    accessor
  );

  const dataIsEmpty =
    !baseData ||
    (baseData && !baseData.length && !Object.keys(baseData).length);

  const { dropdownProps, chartProps } = useAlternativeDataSelectorPortal(
    baseData,
    baseMeta
  );
  // Use alternative data for alternative visualizations
  const finalMeta =
    (baseMeta?.alternativeValueNodeId && chartProps?.meta) || baseMeta;
  const finalData =
    (baseMeta?.alternativeValueNodeId && chartProps?.data) || baseData;
  const compileDataModalTitle = useCallback(
    () => getDataModalTitle(title, finalMeta.alternativeValueNodeId),
    [title, finalMeta.alternativeValueNodeId, dropdownProps?.value]
  );
  const exportDataset = exportAsData(
    finalData,
    finalMeta,
    makeTableData,
    compileDataModalTitle,
    getExportTitle
  );
  const exportAsImage = useCallback(
    () =>
      exportElementAsImage(
        document,
        chartId,
        () => getExportTitle(compileDataModalTitle(), t({ id: 'chart' })),
        () => {}
      ),
    [document, chartId, getExportTitle, compileDataModalTitle]
  );
  return (
    <ChartSegment>
      {(finalMeta?.alternativeValueNodeId && (
        <AlternativeChartDropdownPortal
          disabled={loading || dataIsEmpty}
          {...dropdownProps}
        />
      )) ||
        null}
      <Header
        style={{
          display: 'inline-flex',
          justifyContent: 'space-between',
          margin: `0 ${svars.spaceNormal}`,
          alignItems: 'center',
        }}
      >
        <span
          style={{
            display: 'inline',
            flexGrow: 1,
            // Force the title to fill and be limited to the available width
            minWidth: 0,
          }}
          data-testid={testid}
        >
          <span>
            <Trans id={title} />
          </span>
          {finalMeta.alternativeValueNodeId ? (
            <span
              id={finalMeta.alternativeValueNodeId}
              style={{ display: 'inline-block', maxWidth: '100%' }}
            />
          ) : null}
          {titleHelper ? (
            <HelpTooltip
              help={[titleHelper]}
              headerText={titleHelperHeader || title}
              iconSize="1rem"
            />
          ) : null}
        </span>
        {!hideMenu ? (
          <ChartMenu
            disabled={loading || dataIsEmpty}
            toggleDataModal={toggleDataModal}
            exportAsImage={exportAsImage}
            exportDataAsXls={exportDataset}
          />
        ) : null}
      </Header>
      {(loading && <LoadingDataVisualization height="100%" />) ||
        (dataIsEmpty && (
          <ComponentWithDimensions
            Component={EmptyDataVisualization}
            props={{ message: noDataMessage }}
          />
        )) || (
          <ComponentWithDimensions
            Component={component}
            props={{
              data: finalData,
              meta: finalMeta,
              formatters,
              loading,
              ...(chartProps || {}),
            }}
            chartId={chartId}
          />
        )}
      {dataModalIsOpen ? (
        <DataModal
          title={compileDataModalTitle}
          data={finalData}
          makeTableData={makeTableData}
          exportAsData={exportDataset}
          meta={finalMeta}
          onClose={toggleDataModal}
          open={dataModalIsOpen}
        />
      ) : null}
    </ChartSegment>
  );
}
DashboardElement.propTypes = {
  chartId: PropTypes.string.isRequired,
  accessor: PropTypes.func.isRequired,
  component: PropTypes.func.isRequired,
  formatters: PropTypes.objectOf(PropTypes.func),
  loading: PropTypes.bool.isRequired,
  makeTableData: PropTypes.func,
  meta: PropTypes.shape(),
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  titleHelper: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  // An optional header for the helper - if not set, title will be used
  titleHelperHeader: PropTypes.string,
  getExportTitle: PropTypes.func.isRequired,
  hideMenu: PropTypes.bool,
  testid: PropTypes.string,
  noDataMessage: commonPropTypes.i18nText,
};
DashboardElement.defaultProps = {
  meta: {},
  formatters: {},
  makeTableData: ({ data }) => ({ data, columns: [] }),
  hideMenu: false,
  titleHelper: null,
  titleHelperHeader: null,
  testid: undefined,
  noDataMessage: undefined,
};

export default DashboardElement;
