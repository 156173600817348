import React, { useCallback, useEffect, useState } from 'react';

import { Trans, msg } from '@lingui/macro';
import debounce from 'lodash/debounce';
import PropTypes from 'prop-types';
import { List } from 'semantic-ui-react';
import styled from 'styled-components';

import HelpTooltip from 'components/ui/HelpTooltip';
import Link from 'components/ui/Link';
import { Checkbox } from 'components/ui/inputs/Checkbox';
import { TextInput } from 'components/ui/inputs/TextInput';

import commonPropTypes from 'utils/commonPropTypes';
import { accentInsensitiveSearch, capitalize, range } from 'utils/helpers';
import capitalizedTranslation from 'utils/i18n';

import * as svars from 'assets/style/variables';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  max-height: inherit;
  min-height: 0;
`;

const StripedListItem = styled(List.Item)`
  &&&&&& {
    display: flex;
    width: 100%;
    justify-content: space-between;
    background: ${(props) =>
      props.odd ? svars.colorWhite : svars.colorLighterGrey};
    padding: ${svars.spaceNormalLarge};

    &:hover {
      & div > label {
        color: ${svars.accentColor} !important;
      }
    }
    &::after {
      content: none;
    }
  }
`;

const CenteredMessage = styled.div`
  color: ${svars.fontColorLighter};
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
`;

const CheckboxLabel = styled.label`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

/**
 * Get raw text label for search purposes.
 * This method is necessary as we receive both "text" labelled data and "label" labelled data.
 * We also allow "i18nLabel" labelled items, but it won't work with search as i18n texts are objects.
 *
 * @param {*} item
 */
const getRawLabel = (item) => item.label || item.text;

function BaseEmptyCheckboxList({ message, onReset, resetMessage }) {
  return (
    <div style={{ position: 'relative' }}>
      {range(9).map((key) => (
        <StripedListItem key={key} odd={key % 2}>
          {' '}
        </StripedListItem>
      ))}
      <CenteredMessage>
        {message ? (
          <Trans render={capitalizedTranslation} id={message} />
        ) : null}
        <Link onClick={onReset}>
          {resetMessage ? (
            <Trans render={capitalizedTranslation} id={resetMessage} />
          ) : null}
        </Link>
      </CenteredMessage>
    </div>
  );
}

BaseEmptyCheckboxList.propTypes = {
  message: commonPropTypes.i18nText.isRequired,
  onReset: PropTypes.func.isRequired,
  resetMessage: commonPropTypes.i18nText,
};

BaseEmptyCheckboxList.defaultProps = { resetMessage: null };

const EmptyCheckboxList = React.memo(BaseEmptyCheckboxList);

function CheckboxList({
  items,
  disabled,
  displaySelectedFirst,
  emptyListMessage,
  extraAction,
  headerStyle,
  isSelectedItem,
  loading,
  loadingDataMessage,
  nMaxSelectedItems,
  noDataMessage,
  onResetItems,
  onSelectItem,
  onSelectItems,
  onUnselectAllItems,
  onUnselectItem,
  placeholder,
  resetTextFilterMessage,
  searchable,
  selectedItems,
  showNSelected,
  style,
}) {
  const [textFilterValue, setTextFilterValue] = useState('');
  const [filteredItems, setFilteredItems] = useState(items);
  const onTextSearch = debounce(
    (value) => {
      setFilteredItems(accentInsensitiveSearch(items, value, getRawLabel));
    },
    200,
    {
      leading: false,
    }
  );
  useEffect(() => {
    setFilteredItems(
      accentInsensitiveSearch(items, textFilterValue, getRawLabel)
    );
  }, [items]);

  const onTextFilterChange = useCallback(
    (e, { value }) => {
      setTextFilterValue(value);
      onTextSearch(value);
    },
    [textFilterValue, filteredItems, items]
  );

  const onResetTextFilter = () => onTextFilterChange(null, { value: '' });

  const onSelectAllFilteredItems = () => {
    const toAdd = filteredItems.filter(
      ({ value }) => !selectedItems.includes(value)
    );
    onSelectItems(toAdd);
  };

  let itemCounter = '-';
  const itemCounterStyles = {
    transition: svars.transitionBase,
    fontWeight: svars.fontWeightMedium,
  };
  const noInputData = !(items && items.length);
  const sortedItems = displaySelectedFirst
    ? filteredItems.sort((a, b) => {
        if (isSelectedItem(a, selectedItems)) {
          return -1;
        }
        if (isSelectedItem(b, selectedItems)) {
          return 1;
        }
        return 0;
      })
    : filteredItems;

  if (selectedItems) {
    itemCounter = selectedItems.length;
    if (nMaxSelectedItems) {
      itemCounter = `${itemCounter} / ${nMaxSelectedItems}`;
      if (selectedItems.length >= nMaxSelectedItems) {
        itemCounterStyles.color = svars.colorDanger;
        itemCounterStyles.fontWeight = svars.fontWeightSemiBold;
      }
    }
  }
  const noMoreItems =
    nMaxSelectedItems &&
    selectedItems &&
    selectedItems.length >= nMaxSelectedItems;

  return (
    <Container style={style}>
      {(showNSelected || onResetItems || onSelectItems) && (
        <span
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            paddingBottom: svars.spaceSmall,
            ...headerStyle,
          }}
        >
          <span style={itemCounterStyles}>
            <Trans>{itemCounter} sélectionné(s)</Trans>
          </span>
          <span>
            {onSelectItems ? (
              <Link
                base="true"
                onClick={onSelectAllFilteredItems}
                style={{ paddingRight: svars.spaceNormalLarge }}
                disabled={loading}
              >
                <Trans render={capitalizedTranslation} id="select-all" />
              </Link>
            ) : null}
            {onResetItems ? (
              <Link
                base="true"
                disabled={!selectedItems.length}
                onClick={onResetItems}
                style={{ paddingLeft: svars.spaceNormal }}
                // We deactivate this as reset can be used in situations where initial state has checked elements
                // In these situations, the action should be available even if selectedItems is empty
                // disabled={!selectedItems.length}
              >
                <Trans render={capitalizedTranslation} id="reset" />
              </Link>
            ) : null}
          </span>
        </span>
      )}
      {searchable && (
        <span
          style={{
            display: 'inline-flex',
            justifyContent: 'space-between',
            alignItems: 'flex-end',
          }}
        >
          {onUnselectAllItems && (
            <HelpTooltip
              compact
              help={
                <Trans id="clear-selection" render={capitalizedTranslation} />
              }
              hoverable={false}
              trigger={
                <span>
                  <Checkbox
                    style={{ margin: `0 ${svars.spaceNormalLarge}` }}
                    indeterminate
                    onClick={selectedItems.length ? onUnselectAllItems : null}
                    disabled={!selectedItems.length}
                  />
                </span>
              }
            />
          )}
          <TextInput
            placeholder={capitalize(placeholder)}
            icon="search"
            style={{
              width: '100%',
              transition: 'all 0.5s ease-out',
            }}
            onChange={onTextFilterChange}
            value={textFilterValue}
          />
        </span>
      )}

      <div
        style={{
          height: 'inherit',
          overflowY: 'auto',
          marginTop: svars.spaceNormal,
        }}
      >
        {sortedItems && sortedItems.length ? (
          <List selection verticalAlign="middle" style={{ height: '100%' }}>
            {sortedItems.map((item, i) => {
              const isItemSelected = isSelectedItem(item, selectedItems);
              const isDisabled = disabled || (!isItemSelected && noMoreItems);
              return (
                <StripedListItem
                  key={`liis-${item.key}`}
                  onClick={() =>
                    isItemSelected ? onUnselectItem(item) : onSelectItem(item)
                  }
                  disabled={isDisabled}
                  odd={i % 2}
                >
                  <Checkbox
                    label={
                      <CheckboxLabel>
                        {(item.i18nLabel && (
                          <Trans
                            render={capitalizedTranslation}
                            id={item.i18nLabel}
                          />
                        )) ||
                          (item.content && item.content) ||
                          getRawLabel(item)}
                      </CheckboxLabel>
                    }
                    checked={isItemSelected}
                    disabled={isDisabled}
                  />
                  {extraAction ? extraAction({ item }) : null}
                </StripedListItem>
              );
            })}
          </List>
        ) : (
          <EmptyCheckboxList
            message={
              (loading && loadingDataMessage) ||
              (noInputData && noDataMessage) ||
              emptyListMessage
            }
            resetMessage={!noInputData ? resetTextFilterMessage : null}
            onReset={onResetTextFilter}
          />
        )}
      </div>
    </Container>
  );
}

export const baseListItemProps = {
  key: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  i18nLabel: commonPropTypes.i18nText,
  // We can use object value to map multiple fields to a given value
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.object,
  ]),
};

CheckboxList.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      ...baseListItemProps,
    })
  ).isRequired,
  // Application (item, selectedItems) => <bool>
  // Item checkbox is checked if true
  isSelectedItem: PropTypes.func,
  selectedItems: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.object])
  ).isRequired,
  onSelectItem: PropTypes.func.isRequired,
  // Define this method to allow multiple selection (select all)
  onSelectItems: PropTypes.func,
  onUnselectItem: PropTypes.func.isRequired,
  onResetItems: PropTypes.func,
  onUnselectAllItems: PropTypes.func,
  placeholder: PropTypes.oneOfType([
    commonPropTypes.i18nText,
    PropTypes.string,
  ]),
  searchable: PropTypes.bool,
  disabled: PropTypes.bool,
  loading: PropTypes.bool,
  displaySelectedFirst: PropTypes.bool,
  style: commonPropTypes.style,
  showNSelected: PropTypes.bool,
  headerStyle: commonPropTypes.style,
  nMaxSelectedItems: PropTypes.number,
  emptyListMessage: commonPropTypes.i18nText,
  noDataMessage: commonPropTypes.i18nText,
  loadingDataMessage: commonPropTypes.i18nText,
  resetTextFilterMessage: commonPropTypes.i18nText,
  // Add an action at the end of each row - the function receives the row item as parameter
  extraAction: PropTypes.func,
};

CheckboxList.defaultProps = {
  showNSelected: false,
  headerStyle: {},
  disabled: false,
  loading: false,
  placeholder: '',
  searchable: false,
  isSelectedItem: (item, selectedItems) => selectedItems.includes(item.value),
  style: {},
  displaySelectedFirst: false,
  onResetItems: null,
  onUnselectAllItems: null,
  onSelectItems: null,
  nMaxSelectedItems: null,
  emptyListMessage: msg({ id: 'no-result' }),
  loadingDataMessage: msg({ id: 'loading' }),
  noDataMessage: msg({ id: 'missing-data' }),
  resetTextFilterMessage: msg({ id: 'empty-filter' }),
  extraAction: null,
};

export default CheckboxList;
